import axios from "../../helpers/axios/axios";
import { BASE_URL } from "../../helpers/common/config";
import { errorHandler } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";
import { setLoading } from "./index";
import moment from "moment";

const setListData = (payload) => {
    console.log(payload);
    return {
      type: actionTypes.SET_LIST_PERASAANKU,
      data: payload.data,
    };
};

export const getListAkuBicara = ( body, success ) => {
    const url = BASE_URL + "/api/akubicara/cek-belajar-gambar";

    console.log(body);
  
    return (dispatch, state) => {
      axios
        .get(url, {
          params: {
            type: body.typee,
          },
        })
        .then((payload) => {
          if (payload.data) {
            // console.log(payload.data.data);
            success(payload.data.data);
            return dispatch(setListData(payload.data));
          }
        })
        .catch((err) => {
          return errorHandler(dispatch, err);
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    };
};

export const getListAkuBicaraAuth = ( body, success ) => {
  const url = BASE_URL + "/api/akubicara/cek-belajar-latihan";

  console.log(body);

  return (dispatch, state) => {
    axios
      .get(url, {
        params: {
          type: body.typee,
        },
      })
      .then((payload) => {
        if (payload.data) {
          // console.log(payload.data.data);
          success(payload.data.data);
          return dispatch(setListData(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

